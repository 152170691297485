import type { BrowserOptions, EdgeOptions, NodeOptions } from '@sentry/nextjs';

const config: BrowserOptions & NodeOptions & EdgeOptions = {
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  sampleRate: 0.05,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.05,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  // uncomment the line below to enable Spotlight (https://spotlightjs.com)
  // spotlight: process.env.NODE_ENV === 'development',
  enabled: process.env.NODE_ENV !== 'development',

  initialScope: {
    tags: { 'app-version': 'ecomm' },
  },
  beforeSend(event, hint) {
    // This is a list of errors that should not be sent to Sentry - https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-ignore-errors
    // Use regex for exact match, can use a string for a partial match
    const ignoreErrors = [
      // AutofillCallbackHandler is a function this is injected in instagram webviews. This does not affect user functionality - https://developers.facebook.com/community/threads/320013549791141/
      /^Can't find variable: _AutofillCallbackHandler$/,
      // Sentry suggests ignoring these errors - https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/16
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      // This error can be replicated when you close out of an okendo review modal. The error is getting thrown from okendo's widget api
      // Sentry recommends ignoring as long as there is no ResizeObserver object you can access in your codebase - https://sentry.io/answers/react-resizeobserver-loop-completed-with-undelivered-notifications/
      /^ResizeObserver loop completed with undelivered notifications.$/,
    ];

    // Get the message or exception from the event
    // @ts-ignore - The type of originalException is unknown in the sentry types
    const message = event.message || hint?.originalException?.message || '';
    // Check if the message matches any of the ignore patterns
    for (const pattern of ignoreErrors) {
      if (typeof pattern === 'string' && message.includes(pattern)) {
        return null; // Ignore the event
      } else if (pattern instanceof RegExp && pattern.test(message)) {
        return null; // Ignore the event
      }
    }

    // If the message doesn't match, send the event to Sentry
    return event;
  },
};

export default config;
